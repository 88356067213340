function createPoint(x, y) {
    return { x: x, y: y };
}
function triangle(ctx, a, b, c, dir, t) {
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(a.x, a.y);
    ctx.lineTo(b.x, b.y);
    ctx.lineTo(c.x, c.y);
    ctx.closePath();
    ctx.scale(0.5 * dir, 0.5 * dir);
    ctx.rotate(Math.sin(t * 0.1));
    ctx.translate(t, t);
    ctx.fill();
    ctx.stroke();
    ctx.restore();
}
function hexagonTriangle(ctx, i, size, t) {
    var angle = Math.PI / 3;
    var x0 = 0;
    var y0 = 0;
    var p0 = createPoint(x0, y0);
    var x1 = size;
    var y1 = 0;
    var p1 = createPoint(x1, y1);
    var x2 = Math.cos(angle) * size;
    var y2 = Math.sin(angle) * size;
    var p2 = createPoint(x2, y2);
    var x3 = (p1.x + p2.x) * 0.5;
    var y3 = (p1.y + p2.y) * 0.5;
    var p3 = createPoint(x3, y3);
    ctx.save();
    ctx.translate(size, Math.sqrt(3) * size * 0.5);
    ctx.rotate((i * Math.PI) / 3);
    triangle(ctx, p0, p1, p3, 1, t);
    triangle(ctx, p0, p3, p2, -1, t);
    ctx.restore();
}
export function hexagon(ctx, size, t) {
    hexagonTriangle(ctx, 0, size, t);
    hexagonTriangle(ctx, 1, size, t);
    hexagonTriangle(ctx, 2, size, t);
    hexagonTriangle(ctx, 3, size, t);
    hexagonTriangle(ctx, 4, size, t);
    hexagonTriangle(ctx, 5, size, t);
}
function hexagonColumn(canvas, ctx, offset, size, w, h, rows, dpr) {
    ctx.save();
    ctx.translate(offset.x, offset.y);
    var i = rows;
    while (i--) {
        ctx.drawImage(canvas, 0, 0, w * dpr, h * dpr, 0, i * h, w, h);
    }
    ctx.restore();
}
export function drawKaleidoscope(hexCtx, hexCanvas, ctx, size, t, dpr) {
    // draw to hexagon 
    hexagon(hexCtx, size, t);
    // size of hexagon
    var hexagonWidth = size * 2;
    var hexagonHeight = Math.sqrt(3) * size;
    // how many cols and rows needed to fill screen?
    var rows = Math.ceil(ctx.canvas.height / hexagonHeight) + 1;
    var cols = Math.ceil((ctx.canvas.width / hexagonWidth) * 1.25) + 1;
    // loop over and draw columns
    var i = cols;
    while (i--) {
        hexagonColumn(ctx.canvas, ctx, {
            x: hexagonWidth * i * 0.75 - hexagonWidth * 0.5,
            y: i % 2 === 0 ? 0 : -hexagonHeight * 0.5
        }, size, hexagonWidth, hexagonHeight, rows, dpr);
    }
}
