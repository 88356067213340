function getColour(freq, i) {
    var width = 75;
    var centre = 180;
    var r = Math.sin(freq * i) * width + centre;
    var g = Math.sin(freq * i + 1) * width + centre;
    var b = Math.sin(freq * i + 2) * width + centre;
    var hex = ((r << 16) | (g << 8) | b).toString(16);
    return "#" + hex;
}
export function generatePattern(accel, sourceSize) {
    if (accel === void 0) { accel = 0.6; }
    if (sourceSize === void 0) { sourceSize = 256; }
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    if (!ctx) {
        return null;
    }
    var n = 16;
    var max = n * n;
    var dim = sourceSize / n;
    var r = Math.PI / max;
    ctx.fillStyle = "#f0fcaa";
    ctx.fillRect(0, 0, sourceSize, sourceSize);
    for (var i = 0; i < max; ++i) {
        var col = i % n;
        var row = Math.floor(i / n);
        var index = Math.floor(Math.abs(Math.sin(i * accel)) * 100);
        ctx.save();
        ctx.translate(col * dim, row * dim);
        ctx.fillStyle = getColour(accel, i);
        ctx.translate(dim / 2, dim / 2);
        ctx.rotate(r * i);
        ctx.translate(-dim / 2, -dim / 2);
        ctx.fillRect(0, 0, dim, dim);
        ctx.restore();
    }
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(sourceSize, 0);
    ctx.lineTo(sourceSize, sourceSize);
    ctx.lineTo(0, sourceSize);
    ctx.closePath();
    return ctx.createPattern(canvas, "repeat");
}
