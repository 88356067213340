import { generatePattern } from "./pattern";
import { drawKaleidoscope } from "./kaleidoscope";
var _dpr = window.devicePixelRatio || 1;
// draw hexagon to canvas
var _hexCanvas;
var _hexCtx;
// draw many hexagons to buffer canvas
var _buffer;
var _bufferCtx;
// screen canvas, part of DOM
var _canvas;
var _ctx;
var _pattern;
// time delta
var dt = 1 / 60;
var t = 0;
function tick() {
    window.requestAnimationFrame(tick);
    update();
    draw();
}
function update() {
    t += dt;
}
function draw() {
    if (!_ctx || !_hexCtx || !_bufferCtx)
        return;
    drawKaleidoscope(_hexCtx, _hexCanvas, _bufferCtx, 50, t, _dpr);
    _ctx.drawImage(_buffer, 0, 0, _buffer.width, _buffer.height, 0, 0, _canvas.width, _canvas.height);
}
function resize() {
    var canvasWidth = window.innerWidth * 0.35;
    _hexCanvas.width = canvasWidth;
    _hexCanvas.height = canvasWidth;
    _buffer.width = canvasWidth;
    _buffer.height = canvasWidth;
    _canvas.width = canvasWidth;
    _canvas.height = canvasWidth;
    if (_bufferCtx && _hexCtx && _pattern) {
        _hexCtx.scale(_dpr, _dpr);
        _hexCtx.fillStyle = _pattern;
        _bufferCtx.scale(_dpr, _dpr);
    }
    _canvas.style.width = canvasWidth + "px";
    _canvas.style.height = canvasWidth + "px";
    if (_ctx) {
        _ctx.scale(_dpr, _dpr);
    }
}
export function init(canvas) {
    _canvas = canvas;
    try {
        _ctx = canvas.getContext("2d");
    }
    catch (e) {
        console.error(e);
    }
    _hexCanvas = document.createElement("canvas");
    try {
        _hexCtx = _hexCanvas.getContext("2d");
    }
    catch (e) {
        console.error(e);
    }
    _buffer = document.createElement("canvas");
    try {
        _bufferCtx = _buffer.getContext("2d");
    }
    catch (e) {
        console.error(e);
    }
    _pattern = generatePattern();
    if (_ctx && _bufferCtx && _pattern) {
        _bufferCtx.fillStyle = _pattern;
        _ctx.fillRect(0, 0, 100, 100);
    }
    // Set canvas sizes
    resize();
    // Start animation loop
    tick();
}
